@import "../../../theme.scss";

.timeline-article {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    margin: 20px 0;

    .marker {
        position: absolute;
        top: 0;
        left: 50%;
        width: 12px;
        height: 12px;
        margin-left: -7px;
        color: #fff;
        border-radius: 100%;
        border: 1px solid black;
        background: #ffffff;
      }
}

@media only screen and (max-width: 700px) {
      .timeline-article {
          .marker {
              margin-left: 0;
              left: 44px;
          }
      }
  }
  