@import '../../theme.scss';

.fcd-title {
    text-align: center;

    .fcd-title__header {
        letter-spacing: 5px;
        font-size: 36px;
    }

    .fcd-title__subtitle {
        font-size: 22px;
    }
}
