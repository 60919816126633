@import '../../theme.scss';

.divider {
    width: 600px;
    padding-bottom: 50px;
    border-top: 1px solid #416054;
    margin: 0 auto;
}

.container-header-image {
    height: 30px;
}

.container-hero {
    
    &.string-hero {
        width: 180px;
    }

    &.paper-hero,
    &.final-hero {
        width: 275px;
    }
}

.fcd-links__container {
    a {
        color: #28483c;
        text-decoration: none;
        font-weight: 600;

        &:active,
        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
}

.fcd-links__subheader {
    font-size: 11px;
    margin-top: 15px;
    display: block;
}

.middle-level {
    margin-top: 30px;
}