@import "../../../theme.scss";

.content-left-container {
    text-align: right;
    max-width: 44%;
    width: 100%;
    float: left;
}
.content-right-container {
    text-align: left;
    max-width: 44%;
    width: 100%;
    float: right;
}

.content-left,
.content-right {
    position: relative;
    width: auto;
    background-color: #fff;
}

p {
    padding: 0;
    margin: 0;
    font-weight: 400;
    color: #242424;
    font-size: 14px;
    line-height: 24px;
    position: relative;
}

@media only screen and (max-width: 700px) {
    .content-left-container,
    .content-right-container {
        max-width: 100%;
        width: auto;
        float: none;
        margin-left: 60px;
        min-height: 53px;
    }

    .content-left,
    .content-right {
        padding: 0 15px;
        min-height: 65px;
    }

    .content-left-container {
        margin-bottom: 20px;
        text-align: left;
    }

    p {
        margin: 0;
    }
  }