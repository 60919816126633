@import '../../theme.scss';

.fcd-footer {
    margin-top: 50px;
    font-size: 12px;
    text-align: center;
}

.fcd-footer__amazon {
    margin-top: 3px;
}