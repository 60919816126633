@import "../../theme.scss";

#conference-timeline {
    padding-top: 30px;
    position: relative;
    max-width: 700px;
    width: 100%;
    margin: 30px auto;

    .conference-center-line {
        position: absolute;
        width: 3px;
        height: 100%;
        top: 0;
        left: 50%;
        margin-left: -2px;
        background: black;
        z-index: -1;
    }
}

@media only screen and (max-width: 700px) {
  #conference-timeline {
    .conference-center-line {
        margin-left: 0;
        left: 50px;
    }
    .timeline-article {
        .marker {
            margin-left: 0;
            left: 44px;
        }
    }
  }
}
