@import '../../theme.scss';

a {
    color: #28483c;
    text-decoration: none;
    font-weight: 600;

    &:active,
    &:focus,
    &:hover {
        text-decoration: underline;
    }
}

.fcd-links__link {
    svg {
        vertical-align: middle;
        margin-left: 5px;
    }
}