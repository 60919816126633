
body {
    font-family: Arial;
}

.no-style-link {
    color: black;
    text-decoration: none;

    &:active,
    &:focus,
    &:hover {
        text-decoration: underline;
    }
}